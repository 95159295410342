import React from "react"
import Layout from "../components/layout"

export default () => (
    <Layout>
        <div>
         HELLO FROM EPISODE PAGE LOLOLOLOL
        </div>
    </Layout>

)

